<template>
  <div class="out">
  <div class="center">
    <div class="top" id="top">
      <div class="heads">
        <div class="avator">
          <img class="header"
            :src="user&&user.MemberPersonalPic?user.MemberPersonalPic:require('../assets/icon_head40.png')" alt="">
          <img v-if="user&&user.AssociatorState=='已过期'" class="vip_icon_normal" src="../assets/vip_icon_normal.png"
            alt="">
          <img v-if="user&&user.AssociatorState=='已开通'" class="vip_icon_normal" src="../assets/vip_icon_selected.png"
            alt="">
        </div>
        <div v-if="!user" @click="goLogin">立即登录</div>
        <div>
          <div class="name" @click="goInfo">
            <div v-if="user">{{user.MemberName}}</div>
            <img src="../assets/me_btn_return.png" alt="">
          </div>
          <div class="times" v-if="user&&user.AssociatorState=='未开通'">暂未开通会员</div>
          <div class="times" v-if="user&&user.AssociatorState=='已过期'">会员已过期</div>
          <div v-if="user&&user.AssociatorState=='已开通'" class="times">
            {{user.AssociatorTimeEnd?user.AssociatorTimeEnd.substring(0,10):''}}到期</div>
        </div>
      </div>
      <div class="vips" @click="goMember">
        <img class="vip_card" src="../assets/vip_img_decorate.png" alt="">
        <span v-if="user&&user.AssociatorState=='未开通'">开通会员 · 享超值权益</span>
        <span v-else>续费会员 · 享超值权益</span>
        <img class="right" src="../assets/vip_icon_return.png" alt="">
      </div>
      <div class="states">
        <div class="sta" @click="goOrder('待服务')">
          <img class="sta_img" src="../assets/indent_icon_wait.png" alt="">
          <div>待服务</div>
        </div>
        <div class="sta" @click="goOrder('服务中')">
          <img class="sta_img" src="../assets/indent_icon_underway.png" alt="">
          <div>服务中</div>
        </div>
        <div class="sta" @click="goOrder('待评价')">
          <img class="sta_img" src="../assets/indent_icon_evaluate.png" alt="">
          <div>待评价</div>
          <img class="line" src="../assets/indent_img_line.png" alt="">
        </div>
        <div class="sta" @click="goOrder('全部')">
          <img class="sta_img" src="../assets/indent_icon_all.png" alt="">
          <div>全部订单</div>
        </div>
      </div>
    </div>
    <div class="bot" id="bot">
      <div class="list" style="margin-bottom:20px">
        <div class="lis" @click="goCase(1)">
          <div class="left">
            <img src="../assets/function_icon_meet.png" alt="">
            <span>约见律师</span>
          </div>
          <img class="li_right" src="../assets/function_btn_forward.png" alt="">
        </div>
        <div class="lis" @click="goCase(2)">
          <div class="left">
            <img src="../assets/function_icon_case.png" alt="">
            <span>案件订单</span>
          </div>
          <img class="li_right" src="../assets/function_btn_forward.png" alt="">
        </div>
      </div>
      <div class="list">
        <div class="lis" @click="goCoupon">
          <div class="left">
            <img src="../assets/function_icon_ticket.png" alt="">
            <span>优惠券</span>
          </div>
          <img class="li_right" src="../assets/function_btn_forward.png" alt="">
        </div>
        <div class="lis" @click="goMouldboard">
          <div class="left">
            <img src="../assets/function_icon_letter.png" alt="">
            <span>合同模板</span>
          </div>
          <img class="li_right" src="../assets/function_btn_forward.png" alt="">
        </div>
        <div class="lis" @click="aboutUs">
          <div class="left">
            <img src="../assets/function_icon_about.png" alt="">
            <span>关于我们</span>
          </div>
          <img class="li_right" src="../assets/function_btn_forward.png" alt="">
        </div>
      </div>
      <div class="outs" v-if="user" @click="sureOut">退出登录</div>
    </div>
    <div class="mark" v-if="show">
      <div class="m_box">
        <div class="m_tip">提示</div>
        <div class="m_confirm">确认退出登录？</div>
        <div class="m_btns">
          <div style="border-right:1px solid #eee" @click="show=false">取消</div>
          <div style="color:#21D0C8" @click="makeSure">确认</div>
        </div>
      </div>
    </div>
    
  </div>
    <FootBox :thistab="4"></FootBox>
  </div>
</template>
<script>
  import FootBox from '@/components/FootBox'
  export default {
    name: 'center',
    data() {
      return {
        cHeight: '',
        user: {
          AssociatorState: '未开通',
          AssociatorTimeEnd: null,
          MemberAge: null,
          MemberArea: null,
          MemberCity: null,
          MemberEmail: null,
          MemberGuid: null,
          MemberHeadPic: null,
          MemberMoblie: null,
          MemberName: null,
          MemberPersonalPic: null,
          MemberProvince: null,
          MemberRealName: null,
          MemberSex: null,
          MemberThirdId: null,
        },
        MemberMoblie: null,
        show: false
      }
    },
    mounted() {
      let hieghts = (document.documentElement.clientHeight || document.body.clientHeight)
      this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      this.$nextTick(res => {
        let hei1 = document.getElementById('top').offsetHeight + document.getElementById('bot').offsetHeight;
        if (hieghts >= hei1) {
          this.cHeight = hieghts + 'px'
        } else {
          this.cHeight = hei1 + 10 + 'px'
        }
        console.log(hieghts, hei1)
      })
      if (this.user) this.MemberMoblie = this.geTel(this.user.MemberMoblie)
      _hmt.push(['_trackPageview', '/center'])
    },
    methods: {
      aboutUs() {
        _hmt.push(['_trackEvent', 'click', '关于我们', 'mob'])
        this.$router.push({
          path: '/about'
        })
      },
      goMember() {
        _hmt.push(['_trackEvent', 'click', '会员中心', 'mob']);
        if (this.user) {
          this.$router.push({
            path: '/member'
          })

        } else {
          this.$router.push({
            path: '/login?to=member'
          })
        }
      },
      goCase(e) {
        if (!this.user) {
          this.$router.push({
            path: '/login',
          })
          return
        }
        this.$router.push({
          path: '/case',
          query: {
            id: e
          }
        })
      },
      goLogin() {
        this.$router.push({
          path: '/login?to=center'
        })
      },
      sureOut() {
        this.show = true
      },
      makeSure() {
        this.user = null
        WebIM.conn.close()
        localStorage.removeItem('user')
        if (localStorage.getItem('zgt')) {
          localStorage.removeItem('zgt')
        }
        this.$router.push('/index')
      },
      geTel(tel) {
        var reg = /^(\d{3})\d{4}(\d{4})$/;
        if (typeof tel === 'number') {
          tel = tel.toString()
        }
        return tel.replace(reg, "$1****$2");
      },
      goInfo() {
        if (this.user) {
          _hmt.push(['_trackEvent', 'click', '个人资料', 'mob']);
          this.$router.push({
            path: '/personInfo'
          })
        } else {
          this.$router.push({
            path: '/login'
          })
        }

      },
      goCoupon() {
        if (!this.user) {
          this.$router.push({
            path: '/login',
          })
          return
        }
        _hmt.push(['_trackEvent', 'click', '优惠券', 'mob']);
        this.$router.push({
          path: '/coupon'
        })
      },
      goMouldboard() {
        if (!this.user) {
          this.$router.push({
            path: '/login',
          })
          return
        }
        _hmt.push(['_trackEvent', 'click', '合同模板', 'mob']);
        this.$router.push({
          path: '/mouldboard'
        })
      },
      goOrder(e) {
        if (!this.user) {
          this.$router.push({
            path: '/login',
          })
          return
        }
        _hmt.push(['_trackEvent', 'click', `我的订单-${e}`, 'mob']);
        this.$router.push({
          path: '/order',
          query: {
            filterStates: e
          }
        })
      }
    },
    components: {
      FootBox
    }
  }

</script>

<style scoped lang="scss">
  .mark {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.5);

    .m_box {
      width: 80%;
      border-radius: 20px;
      background: #fff;

      .m_tip {
        padding-top: 20px;
        font-size: 36px;
        text-align: center;
      }

      .m_confirm {
        margin-top: 30px;
        text-align: center;
        font-size: 36px;
      }

      .m_btns {
        display: flex;
        border-top: 1px solid #eee;
        margin-top: 40px;

        div {
          width: 50%;
          height: 90px;
          line-height: 90px;
          text-align: center;
          font-size: 30px;
        }
      }
    }
  }

  .center {
    padding-bottom: 20px;
    position: relative;
    background: #F5F5F8;
    height: 100%;
  }
.out{
  position: absolute;
    width: 100%;
    height: calc(100% - 98px);
    top: 0;
    left: 0;
    overflow: auto;
}
  .top {
    height: 708px;
    background-image: url('../assets/me_img_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .heads {
    display: flex;
    padding: 70px 50px 60px;
    height: 120px;
    align-items: center;
    font-size: 36px;
    color: #333;
  }

  .avator {
    position: relative;
    margin-right: 29px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 4px solid #fff;

    .header {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .vip_icon_normal {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 33px;
      height: 30px;
    }
  }

  .name {
    display: flex;
    align-items: center;
    font-size: 36px;
    color: #333333;
    font-weight: bold;

    img {
      margin-left: 20px;
      width: 14px;
      height: 26px;
    }

    div {
      max-width: 450px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .times {
    margin-top: 10px;
    font-size: 26px;
    color: #666666;
  }

  .vips {
    margin: auto;
    position: relative;
    width: 692px;
    height: 120px;
    line-height: 120px;
    font-size: 34px;
    font-weight: bold;
    color: #855C41;
    text-align: right;
    background-image: url('../assets/vip_img_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .vip_card {
      position: absolute;
      left: 14px;
      bottom: 11px;
      height: 137px;
      width: 166px;
    }

    .right {
      margin: 0 30px;
      width: 26px;
      height: 20px;
    }
  }

  .states {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 30px auto;
    width: 692px;
    height: 200px;
    background: #FFFFFF;
    border-radius: 40px;
  }

  .sta {
    position: relative;
    font-size: 26px;
    color: #333;
    text-align: center;

    .sta_img {
      margin-bottom: 10px;
      width: 70px;
      height: 70px;
    }

    .line {
      position: absolute;
      top: 10px;
      right: -63px;
      width: 50px;
      height: 90px;
    }
  }

  .bot {
    position: absolute;
    left: 0;
    width: 100%;
    top: 630px;

    .list {
      margin: auto;
      width: 692px;
      // height: 270px;
      background: #FFFFFF;
      border-radius: 40px;
    }
  }

  .lis {
    display: flex;
    justify-content: space-between;
    margin: 0 42px;
    height: 90px;
    align-items: center;
    color: #333333;
    font-size: 26px;
  }

  .left {
    display: flex;
    align-items: center;

    img {
      margin-right: 12px;
      width: 32px;
      height: 32px;
    }
  }

  .li_right {
    width: 8px;
    height: 22px;
  }

  .outs {
    margin: 30px auto;
    width: 690px;
    height: 100px;
    text-align: center;
    line-height: 105px;
    font-size: 26px;
    color: #333333;
    background: #FFFFFF;
    border-radius: 20px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 120px;
    height: 120px;
    background-color: #fff;
  }

</style>
<style>


</style>
